<template>
     <div className="animated fadeIn">
       <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Map Movies
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">         

          
          
          <!--
            <div slot="movieId" slot-scope="props">
            <vue-bootstrap-typeahead
            :data="movieList"
            v-model="thirdpartyMovieId"
            size="sm"
            :serializer="s => s.text"
            placeholder="Enter Movie Name..."
            @hit="onChangeMovieDropdwon($event,props.row.thirdpartyID, props.row.Movie,props.index)"
            /> 
          </div>
            -->
            <div slot="movieId" slot-scope="props">
            <v-select ref='theVSelect' class="abs"
              :options.sync="movieList"
              label="Title"            
              :clearable="false"
              :reduce="movies => movies.MovieMasterID"
              @input="onChangeMovieDropdwon($event,props.row.thirdpartyID, props.row.Movie,props.index)" 
              placeholder="Select Movie...">
            </v-select>
          </div>

          <div slot="thirdpartyMovieID" slot-scope="props">
            <i v-if="props.row.movieId === '0'" class="fa fa-edit" @click="mapMovie(props.row.thirdpartyID, props.row.Movie)"></i>
            <b-button v-if="props.row.movieId !== '0'" @click="submitThirdPartyMovie(props.row.movieId,props.row.thirdpartyID, props.row.Movie)" type="submit" variant="success" block>Submit</b-button>
            <!-- <h3 >{{ props.row.movieId}}</h3> -->
          </div>
          

        </v-client-table>
      </b-card-body>
    </b-card>
  </div>    
</template>
<script>
import vSelect from "vue-select";
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";

export default {
  name: "MapMovies",
  components: {
    vSelect,
    cSwitch
  },
  data: function() {
    return {
      // Saif
      thirdpartyMovieId: "",
      movieList: [],
      testList: [
        {
          text: "S",
          value: "1"
        },
        {
          text: "N",
          value: "0"
        },
        {
          text: "W",
          value: "3"
        }
      ],
      // Saif
      columns: [
        "Provider",
        "Cinema",
        "Movie",
        "thirdpartyID",
        "movieId",
        "thirdpartyMovieID"
      ],
      data: [],
      options: {
        headings: {
          Provider: "Provider",
          Cinema: "Cinema",
          Movie: "Movie",
          thirdpartyID: "Third Party ID",
          movieId: "Movie Dropdown",
          thirdpartyMovieID: "Map"
        },
        sortable: ["Provider", "Cinema", "Movie"],
        filterable: ["Provider", "Cinema", "Movie"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getProviderMovies();
    this.getMoviesList();
  },
  watch: {},
  methods: {
    // Saif
    submitThirdPartyMovie: function(MovieID, ThirdpartyID, movieName) {
      // if (this.thirdpartyMovieId !== "") {
      if (MovieID !== "0") {
        let payload = {
          ThirdpartyID,
          MovieID
        };
        MovieService.addThirdPartyMovies(payload)
          .then(response => {
            const { data } = response;
            if (data.Status) {
              this.getProviderMovies();
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      } else {
        this.showMessageSnackbar("Oops! Please Select a movie");
      }
    },
    // onChangeMovieDropdwon: function(e, thirdpartyID, movieName, index) {
    //   // this.data[index - 1].movieId = e.value;
    //   },
    onChangeMovieDropdwon: function(e, thirdpartyID, movieName, _index) {
      console.log("eeeee", e, thirdpartyID, movieName, _index);
      this.thirdpartyMovieId = thirdpartyID;
      let index = _.findIndex(this.data, { thirdpartyID: thirdpartyID });
      this.data[index].movieId = e.MovieID;
      console.log("this.data[index]", this.data[index]);
    },
    getMoviesList: function() {
      this.movieList = [];
      // this.movieList.push({
      //   text: "Select Movie",
      //   value: "0"
      // });
      MovieService.getMovies()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.movieList = data.MovieList;
            // data.MovieList.map(o => {
            //   this.movieList.push({
            //     text: o.Title,
            //     value: o.MovieID
            //   });
            // });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getProviderMovies: function() {
      this.data = [];
      // let res = await MovieService.getProviderMovies();
      // this.data = res.data;
      MovieService.getProviderMovies()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MapMovieList.length > 0) {
            data.MapMovieList.map(x => {
              x["movieId"] = "0";
              this.data.push(x);
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    mapMovie: function(thirdpartyID, movieName) {
      this.$router.push({
        path: "/movies/add-movie",
        query: {
          thirdpartyID,
          movieName
        }
      });
    },
    // Saif
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "Movies");
      let payload = {
        TableName: "Movies",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.abs span {
  position: absolute;
}
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.abs .dropdown-toggle .clear {
  display: none;
}
</style>
<style scoped>
</style>
